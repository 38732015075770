import { useState, ChangeEvent, FormEvent } from "react";
import {UPDATE_USER} from '../queries/updateUser';
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CreateAccountComponent from "../components/createAccount";
import Notification from "../components/notification";

function CreateAccount() {
  // State for email and password
  const [password, setPassword] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  //hardcoded for now -- when otp and email is implemented, these will be accessible thru tokens
  //note : can i use the same functionality we have set up for mobile driver onboarding email?  
  const userId='6630483c2129e072b7b6069e'
  const role = 'Manager'

  const [updateUser] = useMutation(UPDATE_USER);
  const navigate = useNavigate();

  // Function to handle changes in the password input field
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    console.log("Form submitted with password:", password);

    try {
      // Call the updateUser mutation and pass the new password
      const { data } = await updateUser({
        variables: {
          id: userId, 
          input: {
            _id: userId,
            password: password, 
            role: role
          },
        },
      });

      console.log("User updated:", data?.updateUser);
            // Show notification overlay
            setShowNotification(true);

            // After 10 seconds, hide the notification and navigate to login page
            setTimeout(() => {
              setShowNotification(false);
              navigate('/');  // Redirect to login page
            }, 5000);  // 10000 ms = 10 seconds
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
    {showNotification && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffb417',
          color: 'black',
          padding: '10px',
          textAlign: 'center',
          zIndex: 1000,
        }}>
          Password updated successfully! You will be redirected to login.
        </div>
      )}
      <div style={{ filter: showNotification ? 'blur(5px)' : 'none', transition: 'filter 0.3s ease' }}>
        <CreateAccountComponent 
          password={password}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
}

export default CreateAccount;
