import { useState } from 'react';

import React from 'react';
import ItwLogo from '../itwIcon/index';

interface CreateAccountProps {
  password: string;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ password, handlePasswordChange, handleSubmit }) => {
  const [reEnteredPassword, setReEnteredPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const handleReEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReEnteredPassword(event.target.value);
  };

  const validatePassword = (password: string): string => {
    const lengthRequirement = password.length >= 12;
    const numberOrSpecialCharRequirement = /[0-9!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!lengthRequirement) {
      return 'Password must be at least 12 characters long';
    }

    if (!numberOrSpecialCharRequirement) {
      return 'Password must include at least one number or special character';
    }

    return '';
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (password !== reEnteredPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setPasswordError('');
    handleSubmit(event); // Proceed with the submit action
  };

  return (
    <div 
        style={{
            backgroundImage: `url(${require('../../assets/backgroundImages/ITW-zoom-2-cropped.jpg')})`, // Uses the require function
            backgroundSize: 'cover', // Ensures the image covers the entire page
            backgroundPosition: 'center', // Centers the image
            minHeight: '100vh', // Makes sure it covers the full viewport height
        }}
    >
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
  <div className="sm:mx-0 sm:w-full sm:max-w-sm"> {/* Changed mx-auto to mx-0 for left alignment */}
    <div>
      <ItwLogo />
    </div>
    <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900"> {/* Changed text-center to text-left */}
      Let's create a password for your account.
    </h2>
    <h1>Password must be 12 characters long and contain one number and one special character.</h1>
  </div>

  <div className="mt-10 sm:mx-0 sm:w-full sm:max-w-sm"> {/* Removed auto centering */}
    <form className="space-y-6" action="#" method="POST" onSubmit={handleFormSubmit}>
      <div>
        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
          Create Password
        </label>
        <div className="mt-2 relative">
          <input
            value={password}
            onChange={handlePasswordChange}
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
            autoComplete="current-password"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-6 text-gray-500"
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>

      {/* Re-Enter Password */}
      <div>
        <label htmlFor="reEnterPassword" className="block text-sm font-medium leading-6 text-gray-900">
          Re-Enter Password
        </label>
        <div className="mt-2 relative">
          <input
            value={reEnteredPassword}
            onChange={handleReEnterPasswordChange}
            id="reEnterPassword"
            name="reEnterPassword"
            type={showPassword ? 'text' : 'password'} // Toggle for re-entered password
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-6 text-gray-500"
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        {passwordError && (
          <p className="text-red-500 text-sm mt-2">{passwordError}</p>
        )}
      </div>

      <div>
        <button
          type="submit"
          style={{ backgroundColor: '#e32661' }}
          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Create Account
        </button>
      </div>
    </form>
  </div>
</div>

    </div>
  );
};

export default CreateAccount;
