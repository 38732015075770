import { gql } from '@apollo/client';

const CHECK_SESSION = gql`
  query CheckSession {
    checkSession {
      isAuthenticated
      role
      locations
      userId
    }
  }
`;

export default CHECK_SESSION;
export { CHECK_SESSION };