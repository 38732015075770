import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { gql, useQuery } from '@apollo/client';
import { FIND_DOCUMENT } from '../../queries/findDocument';

import { Circles } from 'react-loader-spinner';
//perhaps pass the presignedURL as a prop to this component and use it to display the image
//this is the slide over that will add a new driver -- use it to add a new user 
interface RightSlideOver3Props {
  closePopover: () => void;
  title: string;
  documentId: string;
}

const RightSlideOver3 = ({ closePopover, title, documentId }: RightSlideOver3Props) => {
    const [open, setOpen] = useState(true);
    const [emailPersonal, setEmailPersonal] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailSent, setEmailSent] = useState(false); 

    const { data, loading, error } = useQuery(FIND_DOCUMENT, {
        variables: { id: documentId },
    });
    console.log("data", data);

  const handleClose = () => {
    setOpen(false); // Close the popover and trigger the exit animation
    closePopover(); // If closePopover does extra cleanup, call it here
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-custom-color transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      {/* Only display success message if email is sent */}
                        <>
                          {/* Header Section */}
                          <div className="px-4 py-4 sm:px-6 bg-gray-700">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-100 uppercase">
                                {title}
                              </Dialog.Title>
                              <div className="ml-3 h-7 flex items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-gray-100 hover:text-gray-400 focus:outline-none"
                                  onClick={closePopover}
                                >
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Image Section */}
                          <div className="flex justify-center mt-6">
                            <img src="" alt="IMAGE FROM DOCUMENT" className="h-20" />
                          </div>

                          {/* Form Section */}
                          <div className="flex-1 px-4 sm:px-6 py-6">
                            <h3 className="text-center text-2xl font-semibold text-gray-900 mb-4">
                              [FILE NAME]
                            </h3>
                          </div>
                        </>
                    </div>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RightSlideOver3;
