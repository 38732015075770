import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import './rightpopover2.css';
import { Circles } from 'react-loader-spinner';

import { useMutation } from '@apollo/client';
import ADD_EMPLOYEE from '../../queries/createEmployeeByEmail'; 
import ADD_PROGRAM from '../../queries/addProgram'; 
import { useUser } from '../../context/userContext';
import './rightpopover2.css';

//this is the slide over that will add a new driver -- use it to add a new user 
interface RightPopoverProps {
  closePopover: () => void;
  title: string;
}

const RightPopover = ({ closePopover, title }: RightPopoverProps) => {
  const [open, setOpen] = useState(true);
  const [emailPersonal, setEmailPersonal] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailSent, setEmailSent] = useState(false); 

  const [addEmployee, { data, loading, error }] = useMutation(ADD_EMPLOYEE);
  const [addProgram] = useMutation(ADD_PROGRAM);

  const handleClose = () => {
    setOpen(false); // Close the popover and trigger the exit animation
    closePopover(); // If closePopover does extra cleanup, call it here
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //locationReference is coming from where? should we have a location dropdown for when they add an employee?
    const locationReference = '65eb43d44f41993c85d658fd'; 

    try {
      const { data: programData } = await addProgram({
        variables: {
          input: {
            name: 'Mobile Driver Onboard',
            status: 'ACTIVE',
            operator: 'TRULIEVE',
          },
        },
      });

      if (programData) {
        const programId = programData.addProgram._id; 

        try {
          const { data: employeeData } = await addEmployee({
            variables: {
              input: {
                emailPersonal,
                emailCompany: emailPersonal,
                locationReference,
                firstName,
                lastName,
                program: [programId], 
                startDate: new Date().toISOString(),
                position:'DRIVER', 
                employmentStatus: 'EMPLOYED',
              },
            },
          });

          console.log('Employee added successfully:', employeeData.addEmployee);
          setEmailSent(true); 
        } catch (employeeError) {
          console.error('Error adding employee:', employeeError);
        }
      }
    } catch (programError) {
      console.error('Error adding program:', programError);
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-custom-color transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      {/* Only display success message if email is sent */}
                      {emailSent ? (
                        <div className="flex-1 flex flex-col justify-center items-center p-4 sm:p-6">
                          <h3 className="text-center text-2xl font-semibold text-gray-600">
                            Employee Email Sent!
                          </h3>
                          <p className="text-gray-500 text-center mt-4">
                            The onboarding form has been sent successfully. You’ll receive an email notification once the driver completes the form.
                          </p>
                          <button
                            type="button"
                            className="update-btn bg-indigo-600 text-white py-1 px-5 rounded"
                            onClick={handleClose}
                          >
                            CLOSE
                          </button>
                        </div>
                      ) : (
                        <>
                          {/* Header Section */}
                          <div className="px-4 py-4 sm:px-6 bg-gray-700">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-lg font-medium text-gray-100 uppercase">
                                {title}
                              </Dialog.Title>
                              <div className="ml-3 h-7 flex items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-gray-100 hover:text-gray-400 focus:outline-none"
                                  onClick={closePopover}
                                >
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Image Section */}
                          <div className="flex justify-center mt-6">
                            <img src="" alt="Onboard a new driver" className="h-20" />
                          </div>

                          {/* Form Section */}
                          <div className="flex-1 px-4 sm:px-6 py-6">
                            <h3 className="text-center text-2xl font-semibold text-gray-900 mb-4">
                              Add Your New Driver
                            </h3>

                            <form onSubmit={handleSubmit}>
                              <div className="space-y-6">
                                <div>
                                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="mt-1 block w-full rounded-xlg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                                <div>
                                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="mt-1 block w-full rounded-xlg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                                <div>
                                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Personal Email
                                  </label>
                                  <input
                                    type="email"
                                    id="emailPersonal"
                                    name="emailPersonal"
                                    value={emailPersonal}
                                    onChange={(e) => setEmailPersonal(e.target.value)}
                                    className="mt-1 block w-full rounded-xlg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>

                              {/* Instructional Text */}
                              <div className="mt-6 text-sm text-gray-500">
                                I’m ready to send this driver an onboarding form to collect their personal driving information.
                                <br />
                                <br />
                                Once they complete this form, you’ll receive an email notification for you to review.
                              </div>

                              {/* Submit Button */}
                              <div className="mt-6 flex justify-end gap-3">
                                <button
                                  type="button"
                                  className="cancel-next-btn text-xs text-black py-1 px-4"
                                  onClick={closePopover}
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  className="update-btn bg-indigo-600 text-white py-1 px-4 rounded"
                                >
                                  SEND
                                </button>
                              </div>
                            </form>

                            {/* Loading/Error Messages */}
                            {loading && (
                                <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex justify-center items-center z-10">
                                    <Circles
                                    height="80"
                                    width="80"
                                    color="#007e9d"  // Adjust the color as per your brand
                                    ariaLabel="loading-spinner"
                                    />
                                </div>
                                )}
                            {error && <p>Error: {error.message}</p>}
                          </div>
                        </>
                      )}

                      {/* Footer */}
                      <div className="sticky bottom-0 bg-white p-4">
                        <hr className="border-t border-gray-400 mt-4 mb-4" />
                      </div>
                    </div>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RightPopover;
