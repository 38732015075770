import { PlusIcon } from '@heroicons/react/20/solid';
import React from 'react';
import './addButton.css';

interface AddButtonProps {
  onClick?: () => void; // Define an optional onClick prop with a function type
}

const AddButton: React.FC<AddButtonProps> = ({ onClick }) => {
  return (
    <>
      {/* <button
        type="button"
        className="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon aria-hidden="true" className="h-5 w-5" />
      </button> */}
      <button
        type="button"
        onClick={onClick}
        className="rounded-full bg-indigo-600 p-1.5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 add-button"
      >
        <PlusIcon aria-hidden="true" className="h-5 w-5" />
      </button>
      {/* <button
        type="button"
        className="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon aria-hidden="true" className="h-5 w-5" />
      </button> */}
    </>
  );
};

export default AddButton;
