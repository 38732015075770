import MainTemplate from '../components/mainTemplate';
import HorizontalNavBar from '../components/horizontalNavBar';
import PageBackButton from '../components/pageBackButton';
import PageTitle from '../components/pageTitle';
import FfdEmployeeChecklist from '../components/ffdEmployeeChecklist';
import InformationComponent from '../components/displayData';
import RightSlideOver3 from '../components/rightSlideOverFeed3';
import SlideOver from '../components/rightSlideOverFeed1';
import SlideOverNav from '../components/slideOverNav';
import BadgeFlatWithDot from '../components/badgeFlatWithDot';
import Feed from '../components/feed/index'; 
import { ReactComponent as EmployeesIcon } from '../assets/employees-list.svg';

import type { NavBarItem } from '../components/horizontalNavBar';

import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import '../css/detailEmployee.css';

import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import GET_EMPLOYEE from '../queries/getEmployee';

import formatDate from '../helpers/formatDate'; 
import Table from '../components/table';
import DocumentUrlComponent from '../helpers/fetchPreSignedURL';

const EmployeeDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialView = queryParams.get('view')?.replace(/_/g, ' ') || 'PROFILE';
    const [currentView, setCurrentView] = useState(initialView);
    const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeView, setActiveView] = useState('driverOnboarding');

    const nav = useNavigate();
    const { id } = useParams();
    
    const { loading: loadingEmployee, error: errorEmployee, data: dataEmployee, refetch } = useQuery(GET_EMPLOYEE, {
        variables: { id: id },
    });

    if (loadingEmployee) return <div>Loading...</div>;
    if (errorEmployee) return <div>Error! {errorEmployee.message}</div>;
    if (!dataEmployee.getEmployeeById || !dataEmployee.getEmployeeById.program || dataEmployee.getEmployeeById.program.length === 0) {
        return <div>No Employee Data Available</div>;
    }

    //the following code is for the badge status, determined by DRIVER ONBAORDING program status
    const programs = dataEmployee.getEmployeeById.program;
    const driverOnboardingProgram = programs.find((program: any) => program.name === 'Mobile Driver Onboard');
    let programStatus;
    if (driverOnboardingProgram) {
        programStatus = driverOnboardingProgram.status;
    }
   
    let fitStatus
    if(programStatus === 'ACTIVE'){
        fitStatus = "NEEDS ATTENTION";
    }else if(programStatus === 'INACTIVE'){
        fitStatus = "IN GOOD STANDING";
    }
    const statusClass = fitStatus === "IN GOOD STANDING" ? "status-fit" : "status-unfit";

    const badgeStatus = fitStatus === "IN GOOD STANDING" ? 'fit' : 'unfit';
    
    //store the s3 key, bucket name on the employee documentReference and don't worry about calling the document collection for retrieval
    //i use this same function a second time in dashboard right slide over feed for drivers 
    //consider makine a helper function for this
    // const documentReference = dataEmployee.getEmployeeById.documentReference.map((reference: any) => ({
    //     documentType: reference.documentType,
    //     documentId: reference.documentId,
    //     s3Bucket: reference.s3Bucket,
    //     s3Key: reference.s3Key,
    //     s3Url: reference.s3Url,
    // }));

    // const firstDataSet = dataEmployee.getEmployeeById.program[0];
    // const criteriaList = firstDataSet.criteria;
    // const itemCount = criteriaList.length;

    //these two functions are for the profile section 
    const checkAndStyleEmpty = (value: any, isDate = false) => {
        if (!value) {
            return <span style={{ color: 'red' }}>Not filled</span>;
        }
    
        // Handle date values
        if (isDate && typeof value === 'string' && Date.parse(value)) {
            return formatDate(value); // Format the date properly
        }
        
        return value;
    };
    

    const formatAddress = (...parts: any[]) => {
        const filteredParts = parts.filter(part => part);
        if (filteredParts.length > 0) {
            return filteredParts.join(', ');
        } else {
            return <span style={{ color: 'red' }}>Not filled</span>;
        }
    };
    
    const getCurrentDateFormatted = () => {
        const today = new Date();
        return today.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

  const informationData = {
        sections: [
            {
                title: 'Driver Information',
                data: [
                    { label: 'Name', value: checkAndStyleEmpty(`${dataEmployee.getEmployeeById.firstName} ${dataEmployee.getEmployeeById.lastName}`) },
                    { label: 'Position', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.position) },
                    { label: 'Gender', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.gender) },
                    { label: 'Date of Birth', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.dob)) },
                    { label: 'Marital Status', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.maritalStatus) },
                    { label: 'Hire Date', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.startDate)) },
                    { label: 'Stop Date', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.stopDate) },
                    { label: 'Employment Status', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.employmentStatus) },
                ],
            },
            {
                title: 'Contact Information',
                data: [
                    { label: 'Personal Email', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emailPersonal) },
                    // { label: 'Company Email', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emailCompany) },
                    // { label: 'Phone', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.phone) },
                    { label: 'Address', value: formatAddress(dataEmployee.getEmployeeById.addressStreet, dataEmployee.getEmployeeById.addressCity, dataEmployee.getEmployeeById.addressState, dataEmployee.getEmployeeById.addressZip) },
                ],
            },
            {
                title: 'Driver\'s License Information',
                data: [
                    { label: 'License Number', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.driversLicenseNum) },
                    { label: 'License Expiration', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.driversLicenseExpiration)) },
                ],
            },
            {
                title: 'Vehicle Information',
                data: [
                    { label: 'Year', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].year) },
                    { label: 'Make', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].make) },
                    { label: 'Model', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].model) },
                    { label: 'Series', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].series) },
                    { label: 'VIN', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.vehicleReference[0].vin) },
                    
                ],
            },
            {
                title: 'Emergency Contact Information',
                data: [
                    { label: 'Name', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emergencyContactName) },
                    { label: 'Relationship', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.relationshipToEmployee) },
                    { label: 'Phone', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.emergencyContactPhone) },
                ],
            },
            // {
            //     title: 'Motor Vehicle Record',
            //     data: [
            //         { label: 'MVR Date', value: checkAndStyleEmpty(formatDate(dataEmployee.getEmployeeById.mvrDate)) },
            //         { label: 'MVR Score', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.mvrScore) },
            //         { label: 'MVR State', value: checkAndStyleEmpty(dataEmployee.getEmployeeById.mvrState) },
            //     ],
            // },
        ],
    };

    //defines navigation items 
    const navBarItems: NavBarItem[] = [
        { label: 'PROFILE', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('PROFILE', event) },
        { label: 'PROGRAMS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('PROGRAMS', event) },
        { label: 'DOCUMENTS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('DOCUMENTS', event) },
        { label: 'LOCATIONS', href: `/employeedetail/`, onClick: (event) => handleNavItemClick('LOCATIONS', event) },
    ];

    const hasDocuments = dataEmployee.getEmployeeById.documentReference && dataEmployee.getEmployeeById.documentReference.length > 0;
    console.log('hasDocuments', hasDocuments);
    // Prepare rowData by mapping over the documentReference array
    const rowData = hasDocuments? dataEmployee.getEmployeeById.documentReference.map((doc: any, index: any) => ({
        documentType: doc.documentType,
        presignedUrl: <DocumentUrlComponent s3Bucket={doc.s3Bucket} s3Key={doc.s3Key} display="small"/>, 
        documentId: doc.documentId,
    }))
    : [];

    const columnDefs=[
        { field: 'documentType', headerName: 'Type', flex: 1 },  
        { field: 'presignedUrl', headerName: 'Preview', flex: 1, cellRenderer: (params: any) => params.value  }, 
        { field: 'documentId', headerName: 'ID', flex: 1 },  
        // { field: 'size', headerName: 'Size', flex: 1 },  
        // { field: 'uploadDate', headerName: 'Upload Date', flex: 1 },
        // { field: 'uploadedBy', headerName: 'Uploaded By', flex: 1 },  
    ]

    const columnDefsLocations = [
        { field: 'locationName', headerName: 'Name', flex: 1 },
        { field: 'locationAddress', headerName: 'Address', flex: 1 },
        { field: 'locationCity', headerName: 'City', flex: 1 },
        { field: 'locationState', headerName: 'State', flex: 1 },
        { field: 'locationZip', headerName: 'Zip', flex: 1 },
        { field: 'locationManager', headerName: 'Manager', flex: 1 },
    ]
    const rowDataLocations = dataEmployee.getEmployeeById.locationReference.map((loc: any) => ({
        locationName: loc.name, 
        locationAddress: loc.addressStreet,
        locationCity: loc.addressCity,
        locationState: loc.addressState,
        locationZip: loc.addressZip,
        // locationManager: loc.locManagerName
    }))

    // const program = dataEmployee.getEmployeeById.program[0];
    // console.log('program',program)

    // const transformFfdDataToChecklistItems = (program: any) => {
    //     if (!program || !Array.isArray(program.criteria)) {
    //         return [];
    //     }
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);

    //     return program.criteria.map((criterion: any) => {
    //         const deadline = new Date(criterion.deadline);
    //         deadline.setHours(0, 0, 0, 0);

    //         const svg = (criterion.criterionStatus === "ACTIVE" && deadline > today) ? 'checkmark' : 'thex2';
    //         return {
    //             content: criterion.description,
    //             svg: svg,
    //         };
    //     });
    // };

    // const isActive = (criteriaList: { criterionName: string, criterionStatus: string }[]): boolean => {
    //     if (!program || !Array.isArray(program.criteria) || program.criteria.length <= activeIndex) {
    //         return false;
    //     }
    //     const criterion = program.criteria[activeIndex];
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);

    //     const deadline = new Date(criterion.deadline);
    //     deadline.setHours(0, 0, 0, 0);

    //     return criterion.criterionStatus === "ACTIVE" && deadline > today;
    // };

    // const activeStatus = isActive(criteriaList);
    //code i just tossed in to test this functionality --passed to the popover

    // const activeStatus = true;

    const handleNavItemClick = (view: string, event: React.MouseEvent) => {
        event.preventDefault();
        setCurrentView(view);
    };

    const handleDriversBackClick = () => {
        nav('/employeepage');
    };

    const handleRowClick = (event: any) => {
        setSelectedRowData(event.data);  // Save clicked row data
        setIsSlideOverOpen(true);  // Open RightSlideOver3
    };
    console.log('selectedRowData', selectedRowData);
    const closeSlideOver = () => {
        setIsSlideOverOpen(false);
    };

    // const handleFieldClick = (index: number) => {
    //     setActiveIndex(index);
    //     setIsPopoverOpen(!isPopoverOpen);
    // };

    const handlePageTitleClick = () => {
        setCurrentView('PROFILE');
        refetch();
    };

    // const determineDriverStatus = (criteriaList: { criterionName: string, criterionStatus: string }[]): string => {
    //     const immediateDisqualifiers = [
    //         "Driver's License Expiration",
    //         "Insurance Expiration",
    //         "Driver Onboarding"
    //     ];
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);

    //     const pastDueCount = program.criteria.reduce((count: number, criterion: { deadline: string | number | Date; }) => {
    //         const deadline = new Date(criterion.deadline);
    //         deadline.setHours(0, 0, 0, 0);
    //         if (deadline <= today) {
    //             return count + 1;
    //         }
    //         return count;
    //     }, 0);

    //     const hasImmediateDisqualifier = program.criteria.some((criterion: { criterionName: string; criterionStatus: string; }) =>
    //         immediateDisqualifiers.includes(criterion.criterionName) &&
    //         criterion.criterionStatus !== "ACTIVE"
    //     );

    //     if (hasImmediateDisqualifier || pastDueCount > 0) {
    //         return "NEEDS ATTENTION";
    //     }

    //     return "IN GOOD STANDING";
    // };




    return (
        <div>
            <MainTemplate />
            <div className="main-container">
            {/* {isPopoverOpen && activeIndex < criteriaList.length && (
                <SlideOver
                    title={criteriaList[activeIndex].description || "No Title"}
                    emailCompany={dataEmployee.getEmployeeById.emailCompany}
                    phone={dataEmployee.getEmployeeById.phone}
                    name={`${dataEmployee.getEmployeeById.firstName} ${dataEmployee.getEmployeeById.lastName}`}
                    employeeId={dataEmployee._id}
                    location={dataEmployee.getEmployeeById.program[0].locationName}
                    licenseNumber={dataEmployee.getEmployeeById.driversLicenseNum}
                    licenseExpiration={dataEmployee.getEmployeeById.driversLicenseExpiration}
                    // criterionId={criteriaList[activeIndex]._id}
                    flyOut={criteriaList[activeIndex].description || "No Description"}
                    isActive={activeStatus}
                    itemCount={itemCount}
                    activeIndex={activeIndex}
                    // navigateToNext={nextCriteria}
                    closePopover={closeAndResetPopover}
                    toggleEdit={() => { }}
                    navItems={[
                        { label: 'Required Info', viewId: 'requiredInfo' },
                        { label: 'Driver', viewId: 'driver' },
                        { label: 'History', viewId: 'history' },
                      ]}
                    employeeid=''
                />
            )} */}
            <div className="header">
                <PageBackButton onClick={handleDriversBackClick} name="Drivers" />
                <div className="title-icons-container">
                    <span className="locations-icon">
                        <EmployeesIcon />
                    </span>
                    <button className="page-title-button" onClick={handlePageTitleClick}>
                        <PageTitle pageTitle={`${dataEmployee.getEmployeeById.firstName} ${dataEmployee.getEmployeeById.lastName}`} />
                    </button>
                </div>
            </div>
            <div>
                {/* <BadgeFlatWithDot title={<span className={`text-xs ${statusClass}`}>{fitStatus}</span>} status={badgeStatus} /> */}
                <BadgeFlatWithDot title={<span className={`text-xs ${statusClass}`}>{fitStatus}</span>} status={badgeStatus} />

                <div>
                    <HorizontalNavBar items={navBarItems} />
                </div>
                {currentView === 'PROGRAMS' && (
                    <SlideOverNav
                        activeView={activeView}
                        setActiveView={setActiveView}
                        navItems={[
                            { label: 'Driver Onboarding', viewId: 'driverOnboarding' }
                        ]}
                        clickable={true}
                    />
                )}
            {/* Conditionally render SlideOverNav for DOCUMENTS */}
            {currentView === 'DOCUMENTS' && (
                <SlideOverNav
                    activeView={activeView}
                    setActiveView={setActiveView}
                    navItems={[
                        { label: 'Uploaded Documents', viewId: 'uploadedDocuments' },
                        { label: 'Other Documents', viewId: 'otherDocuments' }
                    ]}
                    clickable={true}
                />
            )}
                <div className="info-component-employee-detail">
                    {currentView === 'PROFILE' && <InformationComponent sections={informationData.sections} />}
                </div>
                    {currentView === 'PROGRAMS' && activeView === 'driverOnboarding' && (
                    <div className="feed-checklist">
                        <div className="checklist">
                          {/* <FfdEmployeeChecklist onItemClicked={handleFieldClick} rowItems={transformFfdDataToChecklistItems(program)} /> */}
                          <FfdEmployeeChecklist onItemClicked={function (index: number): void {
                                    throw new Error('Function not implemented.');
                                } } rowItems={[]}  />

                        </div>
                        <div className="feed">
                            <h1></h1>
                            <div className={`status-rectangle ${fitStatus === "IN GOOD STANDING" ? 'complete' : 'incomplete'}`}>
                                {fitStatus === "IN GOOD STANDING" ? <CheckIcon className="icon" /> : <XMarkIcon className="icon" />}
                                <span>{fitStatus === "IN GOOD STANDING" ? `Completed on ${getCurrentDateFormatted()}` : `Incomplete as of ${getCurrentDateFormatted()}`}</span>
                            </div>
                            <Feed />
                        </div>
                      </div>
                    )}                    
                    {currentView === 'DOCUMENTS' && activeView === 'uploadedDocuments' && (
                    <>
                        <div>
                            {dataEmployee.getEmployeeById.documentReference && dataEmployee.getEmployeeById.documentReference.length > 0 ? (
                                <Table columnDefs={columnDefs} rowData={rowData}  onRowClick={handleRowClick}/>
                            ) : (
                                <p>No documents available</p> 
                            )}
                        {isSlideOverOpen && (
                            <RightSlideOver3 
                                closePopover={closeSlideOver} 
                                title={'FILE PREVIEW'}  
                                documentId={selectedRowData.documentId}                             
                            />
                        )}
                        </div>
                    </>
                    )}
                    {currentView === 'LOCATIONS' && (
                        <Table columnDefs={columnDefsLocations} rowData={rowDataLocations} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetail;
