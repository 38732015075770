import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useQuery, gql } from '@apollo/client';
import CHECK_SESSION from '../queries/checkSession';

interface UserContextProps {
  userRole: string | null;
  userLocations: string[] | null;
  userId: string | null;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { data, loading, error } = useQuery(CHECK_SESSION, {
    fetchPolicy: 'network-only',
  });

  const [userRole, setUserRole] = useState<string | null>(null);
  const [userLocations, setUserLocations] = useState<string[] | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    if (data && data.checkSession.isAuthenticated) {
      setUserRole(data.checkSession.role);
      setUserLocations(data.checkSession.locations);
      setUserId(data.checkSession.userId);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <UserContext.Provider value={{ userRole, userLocations, userId }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
