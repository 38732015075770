import { CheckIcon, HandThumbUpIcon, UserIcon, StopIcon, XCircleIcon, PaperAirplaneIcon, InboxIcon, EnvelopeOpenIcon, LinkIcon } from '@heroicons/react/20/solid';
import React, { SVGProps, ForwardRefExoticComponent, RefAttributes } from 'react';

interface TimelineEvent {
  id: number;
  content: string;
  target: string;
  href: string;
  date: string;
  datetime: string;
  icon: ForwardRefExoticComponent<SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>>;
  iconBackground: string;
}

const timeline: TimelineEvent[] = [
  {
    id: 1,
    content: 'Mobile Onboarding Initiated by ',
    target: '[Manager Name]',
    href: '#',
    date: 'May 05',
    datetime: '2024-05-20',
    icon: CheckIcon,
    iconBackground: 'bg-custom-pom',
  },
  {
    id: 3,
    content: 'Mobile Onboarding Completed by ',
    target: '[Driver Name]',
    href: '#',
    date: 'May 06',
    datetime: '2024-05-06',
    icon: CheckIcon,
    iconBackground: 'bg-custom-pom',
  },
  {
    id: 4,
    content: 'Mobile Onboarding Information Approved by ',
    target: '{Manager Name}',
    href: '#',
    date: '--',
    datetime: '',
    icon: XCircleIcon,
    iconBackground: 'bg-gray-500',
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Feed: React.FC = () => {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 && (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              )}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white text-gray-500"
                    style={{ fontSize: '29px', lineHeight: '0' }} // Increased font size and reduced line height
                  >
                    &#8226; {/* This is the bullet point */}
                  </span>
                </div>
                <div className="min-w-0 flex-1 flex justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{' '}
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                  <div className="text-right text-sm text-gray-500 whitespace-nowrap">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Feed;
