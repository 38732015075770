import React from 'react'
import { AgGridReact } from 'ag-grid-react'; 
import { GridOptions, ColDef, GridReadyEvent } from 'ag-grid-community';
import './table.css';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";

import { useNavigate } from 'react-router-dom'

interface TableProps {
    columnDefs: ColDef[];
    rowData: any[];
    onRowClick?: (event: any) => void;
}

const Table = ({columnDefs, rowData, onRowClick}: TableProps) => {
    let nav = useNavigate();

    return (
        <div className="ag-theme-quartz" >
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                onRowClicked={onRowClick}
            />
        </div>
    )
}

export default Table;