import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import GET_EMPLOYEES_WITH_OUTLIERS from '../queries/dashboardOutlier';
import { useQuery } from '@apollo/client';
import { useOverdueTasks } from '../context/overdueTasksContext';


import MainTemplate from '../components/mainTemplate';
import StatsComponent from '../components/statsDashboard/index';
import PageTitle from '../components/pageTitle';
import Calendar from '../components/dashboardCalendar/index';
import SlideOver from '../components/rightSlideOverFeed1/index';

import DashboardIcon from '../assets/dashboardIcon.svg';

import { CalendarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import '../css/App.css';
import '../components/statsDashboard/statsDashboard.css';

interface Stat {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
}

interface Calendar {
  id: number;
  DATE: string;
  time: string;
  datetime: string;
  NAME: string;
  LOCATIONS: string;
  TYPE: string;
  email: string;
  phone: string;
  licenseNumber: string;
  active: boolean;
}

interface Criterion {
  _id: string;
  description: string;
  deadline: string;
}

interface Program {
  _id: string;
  criteria: Criterion[];
}

interface LocationReference {
  _id: string;
  name: string;
  addressState: string;
}

interface Employee {
  _id: string;
  location: string;
  outlierCount: number;
  firstName: string;
  lastName: string;
  locationReference: LocationReference[];
  program: Program[];
  driversLicenseState: string;
  driversLicenseExpiration: string;
  driversLicenseNum: string;
  emailCompany: string;
  phone: string;
}

// interface RowData {
//   ID: string;
//   TYPE: string;
//   EMPLOYEE: string;
//   STATUS: string;
//   LOCATIONS: string;
//   STATE: string;
//   DATE: string;
//   EMAIL: string;
//   PHONE: string;
//   NAME: string;
//   LICENSE_NUMBER: string;
//   LICENSE_EXPIRATION: string;
//   CRITERION_ID: string;
// }

const DriverAnalytics: React.FC = () => {
  const navigate = useNavigate();

  const [isListHovered, setIsListHovered] = useState(false);
  const [isDashHovered, setIsDashHovered] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState<Calendar | null>(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  // const [rowData, setRowData] = useState<Calendar[]>([]);
  const { data, error, loading } = useQuery(GET_EMPLOYEES_WITH_OUTLIERS);
  const { overdueTasks, setOverdueTasks, weekTasks, setWeekTasks, monthTasks, setMonthTasks } = useOverdueTasks();
  console.log("selectedCalendar", selectedCalendar)

  const isDateInCurrentMonth = (date: string): boolean => {
    const targetDate = new Date(date);
    const today = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return (
      targetDate >= today && targetDate <= endOfMonth
    );
  };
  
  const isDateInUpcomingWeek = (date: string): boolean => {
    const targetDate = new Date(date);
    const today = new Date();
    const oneWeekFromToday = new Date();
    oneWeekFromToday.setDate(today.getDate() + 7);
    return targetDate >= today && targetDate <= oneWeekFromToday;
  };

  const isDateOverdue = (date: string): boolean => {
    const targetDate = new Date(date);
    const today = new Date();
    return targetDate < today;
  };

  const transformEmployeeData = (employee: Employee) => {
    return employee.program.flatMap((program) => {
      return program.criteria.map((criterion) => {
        return {
          ID: employee._id,
          TYPE: criterion.description,
          EMPLOYEE: `${employee.firstName} ${employee.lastName}`,
          STATUS: 'Open',
          LOCATIONS: employee.location,
          STATE: employee.locationReference[0]?.addressState || 'Unknown',
          DATE: criterion.deadline,
          EMAIL: employee.emailCompany,
          PHONE: employee.phone,
          NAME: `${employee.firstName} ${employee.lastName}`,
          LICENSE_NUMBER: employee.driversLicenseNum,
          LICENSE_EXPIRATION: employee.driversLicenseExpiration,
          CRITERION_ID: criterion._id,
        };
      });
    });
  };

  let rowData = [] as Calendar[];
  const today = new Date();

  try {
    if (data && data.getEmployeesWithOutliers) {
      rowData = data.getEmployeesWithOutliers.flatMap((employee: Employee) =>
        transformEmployeeData(employee)
      );
      // For demo purposes only! Remove the following two lines for production
      const fifthOfData = Math.ceil(rowData.length / 12);
      rowData = rowData.slice(0, fifthOfData);
    }
  } catch (err) {
    console.error('Error accessing data', err);
  }

  const filteredRowData = rowData.filter((calendar: Calendar) =>
    isDateInCurrentMonth(calendar.DATE) || isDateOverdue(calendar.DATE)
  );

  useEffect(()=>{
    const overdueTasksArray = rowData.filter((task:any) => isDateOverdue(task.DATE));
    const weekTasksArray = rowData.filter((task:any) => isDateInUpcomingWeek(task.DATE));
    const monthTasksArray = rowData.filter((task:any) => isDateInCurrentMonth(task.DATE));

    if (JSON.stringify(overdueTasks) !== JSON.stringify(overdueTasksArray)) {
      setOverdueTasks(overdueTasksArray);
    }

    // Only set weekTasks if there is a change in data
    if (JSON.stringify(weekTasks) !== JSON.stringify(weekTasksArray)) {
      setWeekTasks(weekTasksArray);
    }

    if(JSON.stringify(monthTasks) !== JSON.stringify(monthTasksArray)) {
      setMonthTasks(monthTasksArray);
    }
  }, [data, overdueTasks, weekTasks, setOverdueTasks, setWeekTasks])

  const overdueTasksCount = overdueTasks.length;
  const eventsThisMonthCount = monthTasks.length;
  const eventsThisWeekCount = weekTasks.length;

  const stats: Stat[] = [
    { name: 'Overdue Tasks', stat: overdueTasksCount.toString(), previousStat: '70,946', change: '12%', changeType: 'increase' },
    { name: 'Upcoming this Week', stat: eventsThisWeekCount.toString(), previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
    { name: 'Upcoming this Month', stat: eventsThisMonthCount.toString(), previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
  ];

  const handleOverdueTasksClick = () => {
    navigate('/dashboard?showOverdueTasks=true');
  };
  const handleWeekTasksClick = () => {
    navigate('/dashboard?showWeekTasks=true');
  }
  const handleMonthTasksClick = () => {
    navigate('/dashboard?showMonthTasks=true');
  }

  const handleListIconMouseEnter = () => {
    setIsListHovered(true);
  };

  const handleListIconMouseLeave = () => {
    setIsListHovered(false);
  };

  const handleDashIconMouseEnter = () => {
    setIsDashHovered(true);
  };

  const handleDashIconMouseLeave = () => {
    setIsDashHovered(false);
  };

  const openSlideOver = (calendar: Calendar) => {
    setSelectedCalendar(calendar);
    setIsSlideOverOpen(true);
  };

  const closeSlideOver = () => {
    setIsSlideOverOpen(false);
    setSelectedCalendar(null);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <div className="">
      <MainTemplate />
      <div className="main-container">
        <div className="header">
          <div className="title-icons-container">
            <div className="logo-container locations-icon">
              <img src={DashboardIcon} alt="Logo" />
            </div>
            <PageTitle pageTitle="Dashboard" />
            <div className="map-icon-location" style={{ marginTop: '2.25vh', marginLeft: '1vw' }}>
              <div
                onMouseEnter={handleDashIconMouseEnter}
                onMouseLeave={handleDashIconMouseLeave}
              >
                {isDashHovered ? (
                  <CalendarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
                ) : (
                  <CalendarIcon
                    className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow"
                    style={{ color: '#e32661' }}
                  />
                )}
              </div>
              <div
                onMouseEnter={handleListIconMouseEnter}
                onMouseLeave={handleListIconMouseLeave}
                onClick={() => navigate('/dashboard')}
              >
                {isListHovered ? (
                  <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-500" />
                ) : (
                  <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400 " />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-1 flex-1 mb-4 overflow-auto">
          <StatsComponent stats={stats} onOverdueTasksClick={handleOverdueTasksClick} onWeekTasksClick = {handleWeekTasksClick} onMonthTasksClick={handleMonthTasksClick}/>
          <div className="">
            <Calendar rowData={filteredRowData} onCalendarClick={openSlideOver} />
          </div>
        </div>
      </div>
      {(isSlideOverOpen && selectedCalendar) && (
        <SlideOver
          title={selectedCalendar.TYPE}
          emailCompany={selectedCalendar.email}
          phone={selectedCalendar.phone}
          name={selectedCalendar.NAME}
          employeeId={selectedCalendar.id}
          location={selectedCalendar.LOCATIONS}
          licenseNumber={selectedCalendar.licenseNumber}
          licenseExpiration={selectedCalendar.DATE}
          flyOut="none"
          isActive={selectedCalendar.active}
          itemCount={0}
          activeIndex={0}
          // navigateToNext={() => {}}
          closePopover={closeSlideOver}
          toggleEdit={() => {}}
          navItems={[
            { label: 'Required Info', viewId: 'requiredInfo' },
            { label: 'Driver', viewId: 'driver' },
            { label: 'History', viewId: 'history' },
          ]}
          employeeid=''
          programArray={[]}
          
        />
      )}
    </div>
  );
};

export default DriverAnalytics;
