import { useQuery } from '@apollo/client';
import { GENERATE_PRESIGNED_URL } from '../queries/generatePreSignedURL';import Table from '../components/table';

const DocumentUrlComponent = ({ s3Bucket, s3Key, display }: { s3Bucket: string, s3Key: string, display:string }) => {
  const { loading, error, data } = useQuery(GENERATE_PRESIGNED_URL, {
    variables: { s3Bucket, s3Key },
  });

  const presignedUrl = data?.generatePresignedUrl?.s3URL;

  if (!presignedUrl) return null; // Make sure to handle undefined URLs

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  console.log('data:', data);
  // const presignedUrl = data?.generatePresignedUrl?.s3URL;


  if (display === 'small') {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* Small thumbnail */}
        <img
          src={presignedUrl}
          alt="Document Preview"
          style={{ width: '50px', height: '50px', objectFit: 'cover' }}
        />
        {/* View Image link */}
        <a href={presignedUrl} target="_blank" rel="noopener noreferrer" title={presignedUrl}>
          View Image
        </a>
      </div>
    );
  }

  if (display === 'large') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* Larger image */}
        <img
          src={presignedUrl}
          alt="Document Preview"
          style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
        />
        {/* View Image link */}
        <a href={presignedUrl} target="_blank" rel="noopener noreferrer" title={presignedUrl}>
        </a>
      </div>
    );
  }
  return null; 
};

export default DocumentUrlComponent;
